import React, { Component } from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import ReportVisit from "./ReportVisit";
import ReportVisitWithSixMounthInterval from "./ReportVisitWithSixMounthInterval";

import LastPayedClient from "./LastPayedClient";
import ReportVisa from "./ReportVisa";
import ReportLicenseClient from "./ReportLicenseClient";
import MoneyTransactions from "./MoneyTransactions";
import MoneyTransactionForHash from "./MoneyTransactionForHash";

import WorkersData from "./WorkersData";
import ClientData from "./ClientData";
import Manot from "./Manot";
import TokefVisa from "./TokefVisa";
import TokefLicense from "./TokefLicense";
import TokefPassport from "./TokefPassport";
import ReportVisitPerPlacement from "./ReportVisitPerPlacement";
import { reportsNames } from "./reportsNames";

import Insurance from "./Insurance";
import TokefInsuranceChanges from "./TokefInsuranceChanges";

import getDataFromApi from "./../../../DinamicForm/getDataFromApi";
import Loading from "../../../-HELPERS-/Loading";
import { Fragment } from "react";
import LinksNoMana from "./LinksNoMana";
import VisitsFuture from "./VisitsFuture";
import WorkersInvoices from "./WorkersInvoices";
import WorkersPays from "./WorkersPays";
import WorkersMoneyBack from "./WorkersMoneyBack";
import ReportPassport from "./ReportPassport";
import AsamaTransactions from "./AsamaTransactions";
import AsamaTransactionsFuture from "./AsamaTransactionsFuture";
import KevaPaysError from "./KevaPaysError";
import MoneyTransactionForHashWorkers from "./MoneyTransactionForHashWorkers";
import MissingKevaPays from "./MissingKevaPays";
import ChargeMissingKevaPays from "./ChargeMissingKevaPays";

//https://lodash.com/docs/4.17.15#fill
//import {find} from 'lodash' //npm i --save lodash

export default class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: false,
      loading: false,
      selectedAction: "",
      categoryName: "",
    };
  }

  selectedCategory = (event) => {
    this.setState({ categoryName: event.target.value }, () => {
      //console.log('resortArray');
    });
  };

  selectedAction = (event) => {
    this.setState({ selectedAction: event.target.value });
  };

  changeScreen = (screenName = false) => {
    this.setState({ selectedAction: screenName });
  };

  sendtoAdmin = (
    url,
    controller,
    objectToSend = {},
    stateNameResponse = false
  ) => {
    this.setState({ loading: true });

    let setStateName;

    if (stateNameResponse) {
      setStateName = stateNameResponse;
    } else {
      setStateName = "data";
    }

    let getData = getDataFromApi(url, objectToSend, controller);
    getData
      .then(function (response) {
        return response;
      })

      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
        //toast.error('שגיאה בשרת. יש למקד את החיפוש');
      })

      .then((getData) =>
        this.setState({ [setStateName]: getData, loading: false }, function () {
          this.setState({ loading: false });
        })
      );
  };

  loadData = () => {
    this.setState({ columnName: this.state.visitType }, function () {
      this.sendtoAdmin("fieldsSearch", "first", this.state, "fields");
    });
  };

  componentDidMount() {
    this.loadData();
  }

  render() {
    //console.log(this.state.fields);

    return (
      <div className="ReportsPage animate__animated animate__fadeIn">
        <Loading open={this.state.loading} noBackdrop={true} />

        <h2 className="titStyle">דוחות</h2>

        {this.state.fields && (
          <div className="contSelect">
            <div className="row justify-content-center">
              <div className="col-lg-4 text-center">
                {/* <h2 className='blueH2'>בחר</h2> */}
                <InputGroup className="customInput">
                  <InputGroup.Prepend className="inputPrependLabel ">
                    <InputGroup.Text>סוג דו"ח</InputGroup.Text>
                  </InputGroup.Prepend>

                  <FormControl
                    as="select"
                    /* 'inputPrepend customSelect' */
                    className="inputPrepend customSelect"
                    onChange={this.selectedCategory}
                    value={this.state.categoryName}
                    name="selectedCategory"
                  >
                    <option value="">בחר...</option>

                    <option value="money">כספים</option>
                    <option value="clients">לקוחות</option>
                    <option value="workers">עובדים</option>
                  </FormControl>
                </InputGroup>
              </div>

              <div className="col-lg-4 text-center">
                {/* <h2 className='blueH2'>בחר</h2> */}
                <InputGroup className="customInput">
                  <InputGroup.Prepend className="inputPrependLabel ">
                    <InputGroup.Text>דו"ח</InputGroup.Text>
                  </InputGroup.Prepend>

                  <FormControl
                    as="select"
                    /* 'inputPrepend customSelect' */
                    className="inputPrepend customSelect"
                    onChange={this.selectedAction}
                    value={this.state.selectedAction}
                    name="selectedAction"
                    disabled={!this.state.categoryName}
                  >
                    <option value="">בחר...</option>

                    {this.state.categoryName === 'clients' && <Fragment>

                      <optgroup label="ביקורי בית">

                        {reportsNames.map((item) => {
                          if (item?.group === 'ביקורי בית') {

                            /* currentState={this.state} */
                            return (
                              <option value={item.id} key={item.id}>
                                {item.name}
                              </option>
                            );


                          } else return false;


                        })}

                      </optgroup>


                    </Fragment>}

                    {this.state.categoryName === 'clients' && <Fragment>

                      <optgroup label="מנות">

                        {reportsNames.map((item) => {
                          if (item?.group === 'מנות') {

                            /* currentState={this.state} */
                            return (
                              <option value={item.id} key={item.id}>
                                {item.name}
                              </option>
                            );


                          } else return false;


                        })}

                      </optgroup>


                    </Fragment>}

                    {reportsNames.map((item) => {
                      if ((item.category === this.state.categoryName) && !item?.group) {

                        /* currentState={this.state} */
                        return (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        );


                      } else return false;


                    })}
                  </FormControl>
                </InputGroup>
              </div>
            </div>
          </div>
        )}

        {this.getComponentBySelect(this.state.selectedAction)}
      </div>
    );
  }

  getComponentBySelect = (selection) => {
    let rakazot = this.props.pageData.rakazot;

    switch (selection) {
      case "1":
        return (
          <ReportVisit
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );
      case "2":
        return (
          <ReportVisitWithSixMounthInterval
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );

      case "3":
        return (
          <MoneyTransactions
            changeScreen={(screenName) => this.changeScreen(screenName)}
          />
        );

      case "23":
        return (
          <AsamaTransactions
            changeScreen={(screenName) => this.changeScreen(screenName)}
          />
        );

      case "24":
        return (
          <AsamaTransactionsFuture
            changeScreen={(screenName) => this.changeScreen(screenName)}
          />
        );

      case "4":
        return (
          <LastPayedClient
            rakazot={rakazot}
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );

      case "5":
        return (
          <WorkersData
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );

      case "6":
        return (
          <Manot
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );

      case "7":
        return (
          <ClientData
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );

      case "8":
        return (
          <Insurance
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );

      case "9":
        return (
          <ReportVisa
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );

      case "22":
        return (
          <ReportPassport
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );

      case "10":
        return (
          <ReportLicenseClient
            rakazot={rakazot}
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );

      case "11":
        return (
          <TokefVisa
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );

      case "12":
        return (
          <TokefLicense
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );

      case "13":
        return (
          <TokefPassport
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );

      case "14":
        return (
          <TokefInsuranceChanges
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );

      case "15":
        return (
          <ReportVisitPerPlacement
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );

      case "16":
        return (
          <MoneyTransactionForHash
            changeScreen={(screenName) => this.changeScreen(screenName)}
          />
        );

      case "26":
        return (
          <MoneyTransactionForHashWorkers
            changeScreen={(screenName) => this.changeScreen(screenName)}
          />
        );

      case "17":
        return (
          <LinksNoMana
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );

      case "18":
        return (
          <VisitsFuture
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );

      case "19":
        return (
          < WorkersInvoices
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );

      case "20":
        return (
          < WorkersPays
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );

      case "21":
        return (
          < WorkersMoneyBack
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );

      case "25":
        return (
          <KevaPaysError
            rakazot={rakazot}
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );

      case "27":
        return (
          <MissingKevaPays
            rakazot={rakazot}
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );
      case "28":
        return (
          <ChargeMissingKevaPays
            rakazot={rakazot}
            changeScreen={(screenName) => this.changeScreen(screenName)}
            fields={this.state.fields}
          />
        );




      default:
        return false;
    }
  };
}
