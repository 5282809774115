import React, { Component } from 'react'
import { Button, Table, Spinner, FormControl, InputGroup } from 'react-bootstrap';
import { converDateToMonthYear } from '../../../convertDateToDb'
import { ConstantsNames, RestUrls } from "../../../Urls";
import MonthSelector from '../Finance/MonthSelector';

export default class ChargeMissingKevaPays extends Component {

    constructor(props) {
        super(props)

        let myDate = new Date();

        //let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        //let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        this.state = {

            socialWorker: '',
            date1: new Date(myDate.toString().replace(/-/g, '/')),
            //date2 : new Date(lastDay.toString().replace(/-/g, '/')),

            branch: '',
            company: '',
            missingPayments: [],
            selectedKevaPayIds: [],
            loading: false,
            processing: false,
            processedResults: {},
            filters: {
                tz: '',
                name: '',
                surname: ''
            },
            sortConfig: {
                key: null,
                direction: 'ascending'
            }

        }
    }

    submit = (event) => {

        //console.log(event);

        this.setState(event,
            function () {
                //this.submitToProps();
            }
        );
    }

    handleSelect = (event) => {


        this.setState({ [event.target.name]: event.target.value }, function () {
            //console.log(this.state)
            //this.submitToProps();
        });

    }

    fetchMissingPayments = async (month, year) => {
        this.setState({ loading: true });
        try {
            var md5 = require('md5');
            let query = '&month=' + month + '&year=' + year;
            let link = ConstantsNames.base_url + '/KevaPayments/getMissingKevaPaymentsForMonth?token=' + md5(RestUrls.Code) + query;

            const response = await fetch(link);
            const data = await response.json();
            this.setState({ missingPayments: data });
        } catch (error) {
            console.error('Error fetching missing payments:', error);
        } finally {
            this.setState({ loading: false });
        }
    }

    handleCheckboxChange = (kevaPayId) => {
        this.setState(prevState => {
            const selectedKevaPayIds = [...prevState.selectedKevaPayIds];
            const index = selectedKevaPayIds.indexOf(kevaPayId);

            if (index === -1) {
                selectedKevaPayIds.push(kevaPayId);
            } else {
                selectedKevaPayIds.splice(index, 1);
            }

            return { selectedKevaPayIds };
        });
    }

    submitSelectedPayments = async () => {

        try {
            this.setState({ processing: true });
            const { month, year } = converDateToMonthYear(this.state.date1);
            const payload = {
                month,
                year,
                kevaPayIds: Array.from(new Set(this.state.selectedKevaPayIds))
            };
            var md5 = require('md5');
            // const response = await fetch(ConstantsNames.base_url + '/Pays/chargeMissingKevaPaymentsForClientsForMonth?token=' + md5(RestUrls.Code), {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(payload)
            // });
            // const res = await response.json();

            // const resultsMap = {};
            // res.forEach(item => {
            //     resultsMap[item.kevaPayId] = {
            //         paymentStatus: item.paymentStatus,
            //         invoiceStatus: item.invoiceStatus
            //     };
            // });

            // this.setState({ processedResults: resultsMap });
        } catch (error) {
            console.error('Error submitting payments:', error);
        } finally {
            this.setState({ processing: false });
        }
    }

    sendData = () => {
        const { month, year } = converDateToMonthYear(this.state.date1);
        // Reset filters and selections before fetching new data
        this.setState({
            filters: {
                tz: '',
                name: '',
                surname: ''
            },
            missingPayments: [],
            selectedKevaPayIds: [],
            processedResults: {},
            sortConfig: {
                key: null,
                direction: 'ascending'
            }
        }, () => {
            this.fetchMissingPayments(month, year);
        });
    }

    handleFilterChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                [name]: value
            }
        }));
    }

    requestSort = (key) => {
        this.setState(prevState => {
            const direction = prevState.sortConfig.key === key &&
                prevState.sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
            return {
                sortConfig: {
                    key,
                    direction
                }
            };
        });
    }

    getFilteredAndSortedPayments = () => {
        const { missingPayments, filters, sortConfig } = this.state;

        // First apply filters
        let filteredPayments = missingPayments.filter(payment => {
            return (
                payment.tz.toLowerCase().includes(filters.tz.toLowerCase()) &&
                payment.name.toLowerCase().includes(filters.name.toLowerCase()) &&
                payment.surname.toLowerCase().includes(filters.surname.toLowerCase())
            );
        });

        // Then apply sorting
        if (sortConfig.key) {
            filteredPayments.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        return filteredPayments;
    }

    render() {
        const filteredPayments = this.getFilteredAndSortedPayments();
        const getSortDirection = (name) => {
            if (this.state.sortConfig.key === name) {
                return this.state.sortConfig.direction === 'ascending' ? '↑' : '↓';
            }
            return '';
        };

        return (
            <section className="ReportsSection animate__animated animate__fadeIn">

                <div className="row">

                    <div className="col-md-3 divBoostrap col-sm-12">
                        <MonthSelector text='מתאריך' as={'dateInput'} value={this.state.date1} placeholder='' name='date1' submit={this.submit} />
                    </div>


                    <div className="col-md-2 divBoostrap col-sm-12">
                        <Button
                            style={{ width: '100%' }}
                            onClick={() => this.sendData()}
                            size=""
                            variant="success"
                            disabled={this.state.loading}
                        >
                            שליחה
                        </Button>
                    </div>

                </div>

                {this.state.missingPayments.length > 0 && (
                    <div className="row mt-4">
                        <div className="col-md-4 mb-3">
                            <InputGroup className='gap-2'>
                                <InputGroup.Text>ת.ז</InputGroup.Text>
                                <FormControl
                                    placeholder="סינון לפי ת.ז"
                                    name="tz"
                                    value={this.state.filters.tz}
                                    onChange={this.handleFilterChange}
                                />
                            </InputGroup>
                        </div>
                        <div className="col-md-4 mb-3">
                            <InputGroup className='gap-2'>
                                <InputGroup.Text>שם</InputGroup.Text>
                                <FormControl
                                    placeholder="סינון לפי שם"
                                    name="name"
                                    value={this.state.filters.name}
                                    onChange={this.handleFilterChange}
                                />
                            </InputGroup>
                        </div>
                        <div className="col-md-4 mb-3">
                            <InputGroup className='gap-2'>
                                <InputGroup.Text>שם משפחה</InputGroup.Text>
                                <FormControl
                                    placeholder="סינון לפי שם משפחה"
                                    name="surname"
                                    value={this.state.filters.surname}
                                    onChange={this.handleFilterChange}
                                />
                            </InputGroup>
                        </div>
                    </div>
                )}

                {this.state.selectedKevaPayIds.length > 0 && (
                    <div className="alert alert-info mt-3">
                        נבחרו {this.state.selectedKevaPayIds.length} פריטים
                    </div>
                )}

                {this.state.loading ? (
                    <div className="text-center mt-4">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">טוען...</span>
                        </Spinner>
                    </div>
                ) : (
                    this.state.missingPayments.length > 0 && (
                        <div className="row mt-4">
                            <div className="col-12">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>בחר</th>
                                            <th onClick={() => this.requestSort('name')} style={{ cursor: 'pointer' }}>
                                                שם {getSortDirection('name')}
                                            </th>
                                            <th onClick={() => this.requestSort('surname')} style={{ cursor: 'pointer' }}>
                                                שם משפחה {getSortDirection('surname')}
                                            </th>
                                            <th onClick={() => this.requestSort('tz')} style={{ cursor: 'pointer' }}>
                                                ת.ז {getSortDirection('tz')}
                                            </th>
                                            <th onClick={() => this.requestSort('cardCash')} style={{ cursor: 'pointer' }}>
                                                סכום {getSortDirection('cardCash')}
                                            </th>
                                            <th>סטטוס חשבונית</th>
                                            <th>סטטוס תשלום</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredPayments.map((payment, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={this.state.selectedKevaPayIds.includes(payment.kevaPayId)}
                                                        onChange={() => this.handleCheckboxChange(payment.kevaPayId)}
                                                    />
                                                </td>
                                                <td>{payment.name}</td>
                                                <td>{payment.surname}</td>
                                                <td>{payment.tz}</td>
                                                <td>{payment.cardCash}</td>
                                                <td>{this.state.processedResults[payment.kevaPayId]?.invoiceStatus || '-'}</td>
                                                <td>{this.state.processedResults[payment.kevaPayId]?.paymentStatus || '-'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                {this.state.selectedKevaPayIds.length > 0 && (
                                    <div className="d-flex align-items-center mt-3">
                                        <Button
                                            variant="primary"
                                            onClick={this.submitSelectedPayments}
                                            disabled={this.state.processing}
                                        >
                                            עיבוד תשלומים נבחרים
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                )}

            </section>
        )
    }
}
