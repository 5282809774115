//category = clients - workers - money

export const reportsNames = [
  {
    id: 1,
    category: "clients",
    name: "דוח ביקור בית רבעוני",
    group: "ביקורי בית",
  },
  {
    id: 2,
    category: "clients",
    name: "דוח ביקורי בית פג תוקף",
    group: "ביקורי בית",
  },
  {
    id: 4,
    category: "money",
    name: "פקיעת הסכמי מסגרת לפי לקוחות",
  },
  {
    id: 6,
    category: "clients",
    name: 'דו"ח מנות',
    group: "מנות",
  },

  {
    id: 7,
    category: "clients",
    name: 'דו"ח השמות חדשות',
  },

  {
    id: 8,
    category: "clients",
    name: "סיומי ביטוח מטופלים",
  },

  {
    id: 10,
    category: "clients",
    name: "פקיעת תוקף היתר העסקה",
  },
  {
    id: 12,
    category: "clients",
    name: "שינוים בתוקף היתר",
  },
  {
    id: 14,
    category: "clients",
    name: "שינוים בתוקף ביטוח",
  },
  {
    id: 15,
    category: "clients",
    name: "דוח בקרת ביקורי בית השמות חדשות",
    group: "ביקורי בית",
  },
  {
    id: 18,
    category: "clients",
    name: "תכנון ביקורי בית לתקופה",
    group: "ביקורי בית",
  },

  {
    id: 17,
    category: "clients",
    name: "שיבוצים שטרם דווחה עליהן מנה לעובד",
    group: "מנות",
  },

  /* {
    id: 5,
    category: "workers",
    name: 'דו"ח מצבת מטפלים',
  }, */
  {
    id: 11,
    category: "workers",
    name: "שינוים בתוקף ויזה",
  },

  {
    id: 9,
    category: "workers",
    name: "פקיעת תוקף ויזה",
  },

  {
    id: 22,
    category: "workers",
    name: "פקיעת תוקף דרכון",
  },

  {
    id: 13,
    category: "workers",
    name: "שינוים בתוקף דרכון",
  },

  {
    id: 16,
    category: "money",
    name: 'דו"ח חשבשבת',
  },

  {
    id: 26,
    category: "money",
    name: 'דו"ח חשבשבת עובדים',
  },

  {
    id: 3,
    category: "money",
    name: "פירוט עסקאות לפי תקופה",
  },

  {
    id: 23,
    category: "money",
    name: "עסקאות השמות",
  },

  {
    id: 24,
    category: "money",
    name: "עסקאות השמה לחיוב",
  },

  {
    id: 19,
    category: "workers",
    name: 'דו"ח עסקאות לביצוע',
  },

  {
    id: 20,
    category: "workers",
    name: 'דו"ח תקבולים',
  },

  {
    id: 21,
    category: "workers",
    name: 'דו"ח החזרים',
  },

  {
    id: 25,
    category: "money",
    name: 'דו"ח שגויים להוראות קבע',
  },
  {
    id: 27,
    category: "money",
    name: 'דיווח על תשלומי הוראת קבע חסרים',
  },
  {
    id: 28,
    category: "money",
    name: "חיוב תשלומי קווה עבור לקוחות נעדרים",
  },
];
